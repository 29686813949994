<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
        <div class="px-3 pt-2 text-white min-vh-100">
          <div class="h1 text-center">GX</div>
          <ul
            class="
              nav nav-pills
              flex-column
              mb-sm-auto mb-0
              align-items-center align-items-sm-start
            "
            id="menu"
          >
            <!-- <li class="nav-item">
              <router-link
                :to="`/admin/`"
                class="nav-link align-middle px-0 text-white"
              >
                <i class="fs-4 bi-house"></i>
                <span class="ms-1 d-none d-sm-inline">首頁</span>
              </router-link>
            </li> -->
            <li class="nav-item">
              <router-link
                :to="`/admin/setting`"
                class="nav-link align-middle px-0 text-white"
              >
                <i class="fs-4 bi-gear"></i>
                <span class="ms-1 d-none d-sm-inline">基本設置</span>
              </router-link>
            </li>
            <!-- <li class="nav-item">
              <a href="#" class="nav-link align-middle px-0 text-white">
                <i class="fs-4 bi-chat-dots"></i>
                <span class="ms-1 d-none d-sm-inline">公告管理</span>
              </a>
            </li> -->
            <li class="nav-item">
              <router-link
                :to="`/admin/member`"
                class="nav-link align-middle px-0 text-white"
              >
                <i class="fs-4 bi-person-circle"></i>
                <span class="ms-1 d-none d-sm-inline">會員管理</span>
              </router-link>
            </li>

            <li class="nav-item">
              <a
                class="nav-link align-middle px-0 text-white"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i class="fs-4 bi bi-journal-text"></i> 報表管理
                <i class="bi bi-chevron-down"></i>
              </a>
              <div class="collapse" id="collapseExample">
                <div class="card card-body">
                  <ul class="ps-0">
                    <li class="nav-item">
                      <router-link
                        :to="`/admin/income`"
                        class="nav-link align-middle px-0 text-secondary"
                      >
                        <i class="bi bi-currency-dollar"></i>
                        <span class="ms-1 d-none d-sm-inline">經紀費管理</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        :to="`/admin/income/brokeragefees_audited`"
                        class="nav-link align-middle px-0 text-secondary"
                      >
                        <i class="bi bi-journal-richtext"></i>
                        <span class="ms-1 d-none d-sm-inline">經紀費報表</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        :to="`/admin/looker_studio/introducer_profit_report`"
                        class="nav-link align-middle px-0 text-secondary"
                      >
                        <i class="bi bi-journal-richtext"></i>
                        <span class="ms-1 d-none d-sm-inline"
                          >介紹人分潤報表</span
                        >
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        :to="`/admin/transcation`"
                        class="nav-link align-middle px-0 text-secondary"
                      >
                        <i class="bi bi-card-text"></i>
                        <span class="ms-1 d-none d-sm-inline">G幣交易紀錄</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <a
                class="nav-link align-middle px-0 text-white"
                data-bs-toggle="collapse"
                href="#collapseBroadcast"
                role="button"
                aria-expanded="false"
                aria-controls="collapseBroadcast"
              >
                <i class="fs-4 bi bi-bell"></i> 推播及公告
                <i class="bi bi-chevron-down"></i>
              </a>
              <div class="collapse" id="collapseBroadcast">
                <div class="card card-body">
                  <ul class="ps-0">
                    <li class="nav-item">
                      <router-link
                        :to="`/admin/notification`"
                        class="nav-link align-middle px-0 text-secondary"
                      >
                        <i class="bi bi-bell"></i>
                        <span class="ms-1 d-none d-sm-inline">推播功能</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        :to="`/admin/notification/announcement`"
                        class="nav-link align-middle px-0 text-secondary"
                      >
                        <i class="bi bi-megaphone"></i>
                        <span class="ms-1 d-none d-sm-inline">公告功能</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        :to="`/admin/notification/calendar`"
                        class="nav-link align-middle px-0 text-secondary"
                      >
                        <i class="bi bi-calendar4-week"></i>
                        <span class="ms-1 d-none d-sm-inline"
                          >推播及公告日程表(建置中)</span
                        >
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col p1-5 px-5" id="admin-container">
        <div class="text-end mt-3 mb-2">
          <!-- <a href="" @click.prevent="update_allMember_variable" class="mx-5"
            >會員下拉清單更新</a
          > -->

          <span v-if="userEmail" class="mx-2">&lt;{{ userEmail }}&gt;</span>
          <a href="" @click.prevent="singOut()">[登出]</a>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<style>
body {
  background-color: #f5f5f5;
}

h2 {
  margin-bottom: 2rem;
}

a {
  text-decoration: none;
}

#admin-container {
  position: relative;
  overflow: clip;
}
</style>

<script>
import { getAuth, signOut } from "firebase/auth";
import { variableService } from "../../services/variableService.js";
export default {
  data() {
    return {
      userEmail: null,
    };
  },
  methods: {
    singOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          // this.$router.push({ name: "Login" });
          // window.location = "/";
        })
        .catch(() => {
          // An error happened.
        });
    },
    update_allMember_variable() {
      variableService.init_allMember();
    },
  },
  created() {
    const auth = getAuth();
    const user = auth.currentUser;
    this.userEmail = user.email;
  },
};
</script>
